import type { StatusResult } from "common-components-nuxt-types/common/statusResult";
import type {
  SignApplicationRequest,
  RemoveSignApplicationRequest,
} from "@/types/user/signData";

export async function useApplicationSign(
  removeSignCalback: () => Promise<unknown>,
) {
  const {
    setPending,
    $reset: resetPending,
    isPending,
    isAnyPending,
  } = useApplicationSignStore();

  const { goToUserAttachmentsPage, pzLogout } = await useAuth();
  const { level, notifications } = await useUserPanel();

  const statusResult = useState<StatusResult | null>("statusResult");
  const signApplicationRequest = ref<SignApplicationRequest>();
  const removeSignRequest = ref<RemoveSignApplicationRequest>();

  const { data: signApplicationResponse, pending: signPending } = await useApi(
    "/api/user/signApplication",
    {
      body: signApplicationRequest,
      method: "post",
      immediate: false,
    },
  );
  signPending.value = false;

  const { data: removeSignResponse } = useApi(
    "/api/user/removeApplicationSign",
    {
      body: removeSignRequest,
      method: "post",
      immediate: false,
    },
  );

  async function signApplication(signerNo: number, applicationId?: number) {
    if (!level.value) {
      return;
    }
    if (notifications.value?.missingAttachment) {
      return goToUserAttachmentsPage();
    }
    setPending(signerNo, applicationId);
    await pzLogout();
    if (applicationId !== undefined) {
      signApplicationRequest.value = {
        applicationId,
        signerNo,
      };
    } else {
      signApplicationRequest.value = {
        level: level.value,
        signerNo,
      };
    }
  }

  function removeSign(signerNo: number, applicationId?: number) {
    if (level.value === undefined) {
      return;
    }
    if (applicationId !== undefined) {
      removeSignRequest.value = {
        applicationId,
        signerNo,
      };
    } else {
      removeSignRequest.value = {
        level: level.value,
        signerNo,
      };
    }
  }

  watch(signApplicationResponse, async (status) => {
    if (status) {
      if (status.type === "SUCCESS" && status.code === "success") {
        await navigateTo(status.title, { external: true });
      } else if (status.code === "attachments") {
        await goToUserAttachmentsPage();
        statusResult.value = status;
      } else {
        statusResult.value = status;
      }
    }
  });

  watch(signPending, (newValue) => {
    if (newValue === false) {
      resetPending();
    }
  });

  watch(removeSignResponse, async (newValue) => {
    if (newValue) {
      if (newValue.type === "SUCCESS") {
        await removeSignCalback();
        statusResult.value = newValue;
      }
    }
  });

  return { signApplication, removeSign, isPending, isAnyPending };
}
