export const useApplicationSignStore = defineStore(
  "applicationSignStore",
  () => {
    const signPending = ref<string>();

    function setPending(signerNo: number, applicationId?: number) {
      if (applicationId !== undefined) {
        signPending.value = `${signerNo}_${applicationId}`;
      }
      signPending.value = `${signerNo}`;
    }

    function $reset() {
      signPending.value = undefined;
    }

    function isPending(signerNo: number, applicationId?: number) {
      if (applicationId !== undefined) {
        return (
          signPending.value !== undefined &&
          signPending.value === `${signerNo}_${applicationId}`
        );
      }
      return (
        signPending.value !== undefined && signPending.value === `${signerNo}`
      );
    }

    function isAnyPending() {
      return signPending.value !== undefined;
    }

    return {
      setPending,
      isPending,
      isAnyPending,
      $reset,
    };
  },
);
